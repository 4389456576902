import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Error from "../../pages/Errors";
import PinVerify from "../../pages/FaceRec/PinVerify";
import Signup from "../../pages/FaceRec/Signup";
import GalleryView from "../../pages/GalleryView";
import FaceResults from "../../pages/GalleryView/components/FaceResults";
import RegisterFace from "../../pages/RegisterFace";

/*--------------------------- New --------------------- */
import AddressAndPayment from "../../pages/AddressAndPayment";
import AddToCart from "../../pages/AddToCart";
import CheckOut from "../../pages/CheckOut";
import PaymentCreditCard from "../../pages/PaymentCreditCard";
import PaymentUpi from "../../pages/PaymentUpi";
import SelectAddress from "../../pages/SelectAddress";
import AddAddressModal from "./AddAddressModal";

import { INDIAN_CURRENCY } from "../../constants";
import CompleteShopping from "../../pages/CheckOut/CompleteShopping";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import SinglePage from "../../pages/SingleImagePage";
import Support from "../../pages/Support";
import Terms from "../../pages/Terms";
import { useEvent } from "../../Store/event";
import DownloadNow from "./DownloadFolders/DownloadNow";
import { Gatag } from "./Gatag";


function Router() {


  
  const eventDetails  = useEvent().response

  const isCountryIndia =
  eventDetails.photographer.printStoreCurrency === INDIAN_CURRENCY;
  
  const stripePromise = loadStripe(isCountryIndia ? process.env.REACT_APP_STRIPE_KEY : process.env.REACT_APP_COREY_STRIPE_KEY);
  return (
    <BrowserRouter>
      <Gatag />
      <Routes>
        <Route
          path="view/:eventName/favourites/:listId"
          element={<GalleryView favourtieImagesVisible={true} />}
        />
        <Route path="/facerec/results/:eventName" element={<FaceResults />} />
        <Route
          path="/facerec/registerface/:eventName"
          element={<RegisterFace />}
        />
        <Route path="/facerec/signup/:eventName" element={<Signup />} />

        <Route
          path="/favourites/:eventName/"
          element={<GalleryView foldersDrawerVisible={true} />}
        />
        <Route
          path="/favourites/:eventName/:listId"
          element={<GalleryView favourtieImagesVisible={true} />}
        />
        <Route
          path="/facerec/:eventName/results/:searchId"
          element={<FaceResults />}
        />
        <Route
          path="/facerec/:eventName/registerface"
          element={<RegisterFace />}
        />
        <Route path="/facerec/:eventName/signup" element={<Signup />} />

        <Route path="/facerec/:eventName" element={<PinVerify />} />
        <Route
          path="/downloadNow/:eventName/:downloadId"
          element={<DownloadNow />}
        />
        <Route path="/view/:eventName/:folderName" element={<GalleryView />} />
        <Route path="/view/:eventName" element={<GalleryView />} />
        <Route path="/view/:eventName/add-to-cart" element={<AddToCart />} />
        <Route
          path="/view/:eventName/checkout"
          element={
            <Elements stripe={stripePromise}>
              <CheckOut />
            </Elements>
          }
        />
        <Route path="/add-address" element={<AddAddressModal />} />
        <Route path="/select-address" element={<SelectAddress />} />
        <Route
          index
          path="/address-and-payment"
          element={<AddressAndPayment />}
        />
        <Route path="/payment-creditcard" element={<PaymentCreditCard />} />
        <Route path="/payment-upi" element={<PaymentUpi />} />
        <Route
          path="/complete-shopping/:eventName"
          element={<CompleteShopping />}
        />
         <Route
          path="/complete-shopping/:eventName/:orderId"
          element={<CompleteShopping />}
        />
        <Route
          path="/complete-shopping/:eventName/:orderId/:downloadId"
          element={<CompleteShopping />}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-condition" element={<Terms />} />
        <Route path="/support" element={<Support />} />
        <Route
          path="/view/:eventName/:folderId/image/:imageId"
          element={<SinglePage />}
        />

        <Route
          path="*"
          element={
            <Error
              className="dark"
              message="Oops, this page doesn't exist!"
              text="Make sure you are opening the correct URL"
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
